hr {
  height: 1px;
  color: rgb(var(--border-color));
}
.vertical-breather--narrow {
  padding-top: 20px;
  padding-bottom: 20px;
}
.price--hidden-compare {
  text-decoration: line-through;
  opacity: 0.7;
}
@media screen and (pointer: fine) {
  .button,
  .shopify-challenge__button,
  #shopify-product-reviews .spr-summary-actions-newreview,
  #shopify-product-reviews .spr-button,
  .shopify-payment-button__button {
    background-image: none;
  }
}
product-meta.product-meta {
  margin-bottom: 12px;
  padding-bottom: 0;
}
product-meta.product-meta .label--highlight {
  background: var(--product-color--highlight);
  color: var(--text-color);
}
product-variants.product-form__variants fieldset.block-swatch-list {
  margin: -4px;
  border: 0;
  padding: 0;
}
product-variants.product-form__variants
  fieldset.block-swatch-list
  .block-swatch__item {
  border: 2px solid var(--product-color--highlight);
}
product-variants.product-form__variants
  fieldset
  .block-swatch__radio:checked
  + .block-swatch__item {
  background-color: var(--product-color--highlight);
}
product-variants.product-form__variants fieldset.block-swatch-list label:after {
  box-shadow: none;
}
.header__linklist-link {
  font-weight: 700;
  letter-spacing: 0.56px;
}
image-with-text .heading.heading--small {
  font-weight: var(--k_subheading-font-weight);
  font-size: var(--k_subheading-font-size);
}
image-with-text .heading.h3 {
  font-weight: var(--k_heading-font-weight);
  font-size: var(--k_heading-font-size);
}
@media screen and (min-width: 1000px) {
  image-with-text .image-with-text-block {
    min-height: 560px;
  }
}
.shop-the-look__dot {
  box-shadow: 0 0 0 8px #fff inset, 0 1px 5px #00000026;
}
.footer__item-social {
  padding-top: 20px;
}
@media screen and (min-width: 1000px) {
  .footer__item-social .footer__item-title {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 768px) {
  .footer__item-title.heading--small {
    font-size: 14px;
  }
}
@media screen and (max-width: 740px) {
  .multi-column--pocket-large .multi-column__inner--scroller {
    grid-auto-columns: 80vw;
  }
}
.shopify-section--collection-banner {
  background: var(--collection-banner-background);
}
@media screen and (max-width: 768px) {
  .shopify-section--collection-banner .image-overlay--small {
    --image-height: 10vh;
  }
  .shopify-section--collection-banner .image-overlay--small h1.heading.h1 {
    margin: 0;
  }
}
.shopify-section--collection-banner .page-header__text-wrapper div {
  font-size: 20px;
}
.shopify-section-center-image {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.shopify-section--featured-collections .section {
  padding: var(--vertical-breather) 0;
}
@media screen and (max-width: 999px) {
  .lp-slideshow .slideshow__banner_image {
    position: relative;
  }

  .lp-slideshow .slideshow__slide-inner:before {
    content: unset;
  }
}
.lp-slideshow img.rating_image {
  margin: 0 auto !important;
}
.lp-slideshow .slideshow_star-rating {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  padding: 20px 0;
}
.lp-slideshow .content-box--left .slideshow_star-rating {
  justify-content: left;
}
.lp-slideshow .content-box--right .slideshow_star-rating {
  justify-content: right;
}
.lp-slideshow .slideshow__text-wrapper h2,
.lp-slideshow .slideshow__text-wrapper p {
  margin: 0 !important;
}
.lp-slideshow .slideshow__text-wrapper .content-box--center .button_image {
  width: var(--button-image_width);
  margin: 0 auto !important;
  padding: 20px 0;
}
.lp-slideshow .slideshow__text-wrapper .content-box--left .button_image,
.lp-slideshow .slideshow__text-wrapper .content-box--right .button_image {
  width: var(--button-image_width);
  padding: 20px 0;
}
.lp-slideshow .stars-wrapper {
  visibility: visible;
}
.lp-slideshow .slideshow__progress-bar:before,
.lp-slideshow .slideshow__progress-bar:after {
  height: 6px;
  background: #fff;
}
.lp-slideshow .slideshow__progress-bar:after {
  background-color: rgba(var(--progress-bar-color), 1);
}
.lp-text-with-icons .text-with-icons__item.reverse {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  gap: 10px;
}
.lp-text-with-icons .text-with-icons__dots {
  margin-top: 5px;
}
.lp-section-gallery .section-gallery .section__header {
  text-align: unset;
}
.lp-section-gallery .gallery__arrow.prev-next-button {
  border-radius: 50% !important;
  background: #fff;
  color: var(--prev-next-button-background);
}
.lp-section-gallery .gallery__progress-bar-wrapper {
  display: none;
}
.lp-section-gallery .gallery__list-wrapper .container {
  margin: 0;
}
.lp-section-gallery .header__grid-wrapper {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: unset;
  max-width: unset;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-bottom: min(48px, var(--vertical-breather));
  padding-left: var(--container-gutter);
  padding-right: var(--container-gutter);
}
@media screen and (max-width: 999px) {
  .lp-section-gallery .header__grid-wrapper {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: unset;
  }
}
.lp-section-gallery .header__button-wrapper {
  justify-content: end;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.lp-section-gallery .header__button-wrapper .button.button--primary {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
@media screen and (max-width: 999px) {
  .lp-section-gallery .header__button-wrapper {
    flex-direction: column;
    align-items: inherit;
  }
}
.lp-section-gallery .gallery__list {
  justify-content: center;
}
.lp-section-gallery .block-code {
  margin: 0 auto;
  max-width: unset;
}
.lp-section-gallery .block-code div#insta-feed {
  display: flex;
  gap: 20px;
}
.lp-section-gallery .gallery-code .instafeed-container {
  padding: 150px !important;
}
product-item.product-item .product-item-meta {
  text-align: left;
}
product-item.product-item .product-item-meta a {
  font-style: normal;
  font-weight: 500;
  line-height: 110%;
}
product-item.product-item .product-item-meta .price {
  font-size: 0.85rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.product-item__quick-form button.button {
  background-image: linear-gradient(
      178deg,
      var(--product-color--highlight),
      var(--product-color--highlight) 10%,
      rgb(var(--root-text-color)) 10%,
      rgb(var(--root-text-color)) 100%
    ),
    linear-gradient(
      var(--product-color--highlight),
      var(--product-color--highlight)
    );
  border: 1px solid rgb(var(--product-color--highlight));
}
.product-item__quick-form button.button:hover .loader-button__text {
  color: var(--product-color--highlight);
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.product-item__quick-form button.button .loader-button__text {
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  font-weight: 700;
}
.subscription-card .lowest-price span {
  opacity: 0.8;
  font-size: 0.625rem;
  font-family: Anderson Grotesk Light, sans-serif;
}
:root {
  --sticky-transition-speed: 0.4s;
}
.subscription-options {
  border: 3px solid var(--product-color--highlight);
  border-radius: 6px;
  padding: 20px;
  background-color: #fff;
}
.subscription-options.sticky {
  position: fixed;
  left: 0;
  right: 0;
  transform: translateY(100px);
  bottom: var(--sticky-button-height);
  animation: slide-in-bottom var(--sticky-transition-speed) ease-in both;
  z-index: 1;
  width: 100%;
  margin-bottom: 0;
  padding: 0;
  border: 0;
  border-top: 2px solid #fff;
}
.subscription-options.sticky-removing {
  animation: slide-out-bottom var(--sticky-transition-speed) ease-in both;
}
.subscription-options.subscription-options--hidden {
  height: 0;
  pointer-events: none;
  overflow: hidden;
  padding: 0;
  border: 0;
}
.subscription-options__group-label {
  display: flex;
  align-items: center;
  justify-content: start;
  letter-spacing: unset;
  font-size: 1rem;
  cursor: pointer;
  text-transform: initial;
  line-height: 1;
  margin: 0;
}
.subscription-options__group-label strong {
  font-weight: 900;
  font-size: 1.1em;
  margin-left: 5px;
}
.subscription-options__group-label .price {
  margin-left: auto;
}
.subscription-options__group-label .subscription-options__label {
  padding-left: 15px;
  text-transform: uppercase;
}
.subscription-options__group {
  padding: 15px 0;
}
.subscription-options__group:not(:last-of-type) {
  margin-bottom: 10px;
}
.sticky .subscription-options__group:not(:last-of-type) {
  margin: 0;
}
.subscription-options__group.active {
  opacity: 1;
}
.subscription-options__group.disabled {
  opacity: 0.7;
  color: #969696;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}
.subscription-options__group:not(.active) {
  opacity: 0.4;
}
.subscription-options__input {
  accent-color: #0e1c4f;
}
.disabled .subscription-options__input {
  accent-color: #969696;
}
.subscription-options__dropdown {
  border: 1px solid #0e1c4f;
  color: #0e1c4f;
  padding-block: 5px;
  margin: 10px 0;
  width: 100%;
  padding: 12px 20px;
  background-color: transparent;
}
.subscription-options__dropdown option {
  color: inherit;
}
.block-subscription-widget.sticky .subscription-options__dropdown {
  margin-bottom: 0;
}
.disabled .subscription-options__dropdown {
  opacity: 0.7;
  color: #969696;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  border-color: #969696;
}
.subscription-selector__usp-wrapper {
  margin-top: 15px;
  font-size: 12px;
}
.subscription-selector__usp-wrapper ul {
  margin: 10px 0 0;
  list-style-type: none;
  padding: 0;
}
.subscription-selector__usp-wrapper li {
  display: flex;
  align-items: center;
  font-size: 12px;
  gap: 8px;
  line-height: 1;
  margin-bottom: 10px;
}
.subscription-selector__usp-wrapper li:before {
  content: '';
  display: block;
  height: 1em;
  width: 1em;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3E%3Cg stroke='%230e1c4f' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'%3E%3Cpath d='M14.25 8.75c-.5 2.5-2.385 4.854-5.03 5.38A6.25 6.25 0 0 1 3.373 3.798C5.187 1.8 8.25 1.25 10.75 2.25'/%3E%3Cpath d='m5.75 7.75 2.5 2.5 6-6.5'/%3E%3C/g%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.subscription-selector__usp-wrapper li:last-child {
  margin-bottom: 0;
}
.subscription-options.sticky .subscription-selector__usp-wrapper {
  display: none;
}
.subscription-spacer.spacer--active {
  height: var(--subscription-block-height);
}
.block-subscription-widget {
  background-color: #fff;
}
.block-subscription-widget.sticky {
  position: fixed;
  left: 0;
  right: 0;
  transform: translateY(100px);
  bottom: var(--sticky-button-height);
  animation: slide-in-bottom var(--sticky-transition-speed) ease-in both;
  z-index: 1;
  width: 100%;
}
.variant-spacer.spacer--active {
  height: var(--variant-spacer-height);
}
.block-variants .sticky {
  position: fixed;
  left: 0;
  right: 0;
  transform: translateY(100px);
  bottom: calc(
    var(--sticky-button-height) + var(--subscription-block-height-compressed) -
      1px
  );
  animation: slide-in-bottom var(--sticky-transition-speed) ease-in both;
  z-index: 1;
  width: 100%;
}
.block-variants .sticky-removing {
  animation: slide-out-bottom var(--sticky-transition-speed) ease-in both;
}
.variant-wrapper.sticky {
  margin: 0;
}
.variant-wrapper.sticky .variant-input-wrap {
  margin: 0;
  flex-direction: row;
  align-items: stretch;
}
.variant-wrapper.sticky label {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  border: 0 !important;
  margin: 0;
}
.variant-wrapper.sticky label span {
  display: none;
}
.subscription-options--collection {
  border: 0;
  padding: 0;
  margin-bottom: 0;
}
.subscription-options--collection .subscription-options__group {
  padding: 5px;
  margin-bottom: 0;
}
.subscription-options--collection .subscription-options__dropdown,
.product-card--has-subscription .product-form__variant-selector {
  margin-bottom: 0;
}
.product-card--has-subscription .variant {
  background-color: #fff;
  margin-bottom: 0;
}
@keyframes slide-in-bottom {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-out-bottom {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100px);
    opacity: 0;
  }
}
quick-buy-drawer .product-item-meta__price-list-container {
  display: none;
}
.linked-products__wrapper {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fill, 90px);
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  gap: 12px;
}
.linked-product__item {
  outline: 3px solid var(--product-color--highlight);
  -webkit-transition: outline-color 0.1s ease-in;
  -o-transition: outline-color 0.1s ease-in;
  transition: outline-color 0.1s ease-in;
  margin: 8px 0 4px;
  height: -webkit-fill-available;
}
@media screen and (max-width: 999px) {
  .linked-product__item {
    max-height: fit-content;
  }
}
.linked-product__item a {
  padding: 11px 18px 13px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  height: 100%;
  justify-content: center;
}
.linked-product__item a[selected],
.linked-product__item:hover {
  background: var(--product-color--highlight);
}
.mega-menu__inner-left {
  display: grid;
  padding-top: 48px;
  padding-bottom: 48px;
  grid-template-columns: 1fr 3fr;
}
.mega-menu__inner-left .mega-menu__images-wrapper--tight {
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
}
.mega-menu__inner-right {
  display: grid;
  padding-top: 48px;
  padding-bottom: 48px;
  grid-template-columns: 3fr 1fr;
}
.mega-menu__inner-right .mega-menu__images-wrapper--tight {
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
}
